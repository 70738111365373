import React, { useEffect, useState } from 'react';
import thiranLogo from '../../assets/thiran.png'; // Adjust path as needed
import eeGif from '../../assets/ee.gif'; // Import the new GIF image

const Hero = () => {
    const [showContent, setShowContent] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 768);

    // Effect to detect screen resize and set mobile/tablet state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
            setIsTablet(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Show content with a fade-in effect after 500ms
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    // Inline styles for responsiveness
    const heroStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        borderRadius: '15px',
        paddingTop: '70px',
        overflow: 'hidden',
    };

    const scrollContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    };

    const logoStyle = {
        width: isMobile ? '180px' : isTablet ? '200px' : '170px',
        height: 'auto',
        marginBottom: '15px',
        marginTop: '30px',
    };

    const contentStyle = {
        fontSize: isMobile ? '20px' : isTablet ? '22px' : '24px',
        fontWeight: 600,
        color: '#333',
        maxWidth: isMobile ? '95%' : '900px',
        textAlign: 'center',
        marginTop: '25px',
        opacity: showContent ? 1 : 0,
        transform: showContent ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 0.5s ease, transform 0.5s ease',
    };

    const aiImageStyle = {
        width: '100%', // Set width to 100% for responsiveness
        height: 'auto', // Maintain aspect ratio
        maxWidth: isMobile ? '280px' : isTablet ? '300px' : '350px', // Limit maximum size
        marginTop: '25px',
        position: isTablet ? 'relative' : 'static',
        bottom: isTablet ? '10px' : '0',
        borderRadius: isTablet ? '0 30px 0 30px' : '0',
    };

    return (
        <div style={heroStyle} id="home">
            <div style={scrollContentStyle}>
                <img src={thiranLogo} alt="Thiran Logo" style={logoStyle} />
                <p style={contentStyle}>
                    <strong>A Global Software powerhouse where innovation meets excellence</strong>
                </p>
                <img src={eeGif} alt="AI Visual" style={aiImageStyle} />
            </div>
        </div>
    );
};

export default Hero;
