import React, { useEffect, useState } from 'react';
import webImg from '../../assets/web app.png';
import androidImg from '../../assets/android.png';
import iosImg from '../../assets/ios.png';
import frontImg from '../../assets/front.png';
import backImg from '../../assets/back.png';
import qaImg from '../../assets/QA.png';
import cloudImg from '../../assets/cloud.png';
import ServicesDescription from './ServicesDescription';

const Programs = ({ activeService }) => {  // Receive activeService as a prop
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const styles = {
    programsContainer: {
      padding: '20px',
      fontSize: '15px',
    },
    scrollingCards: {
      display: 'flex',
      overflowX: 'auto',
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
      msOverflowStyle: 'none', // Hide scrollbar for Internet Explorer and Edge
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '200px',
      margin: '10px',
      textAlign: 'center',
      cursor: 'pointer',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      height: '400px',
    },
    cardImg: {
      width: '100%',
      height: '400px',
      objectFit: 'cover',
      marginBottom: '1px',
    },
    cardText: {
      fontWeight: 'bold',
      margin: '5px 0 0 0',
      transition: 'color 0.3s ease',
    },
  };

  return (
    <div id="programs" style={styles.programsContainer}>
      <h2 style={{fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',}}>{activeService || 'Our Services'}</h2> {/* Display active service text */}

      <div style={styles.scrollingCards}>
        <div style={styles.card}>
          <img src={webImg} alt="Web Development" style={styles.cardImg} />
          <p style={styles.cardText}>Web Development</p>
        </div>
        <div style={styles.card}>
          <img src={androidImg} alt="Android Development" style={styles.cardImg} />
          <p style={styles.cardText}>Android Development</p>
        </div>
        <div style={styles.card}>
          <img src={iosImg} alt="iOS Development" style={styles.cardImg} />
          <p style={styles.cardText}>IOS Development</p>
        </div>
        <div style={styles.card}>
          <img src={frontImg} alt="Front End Development" style={styles.cardImg} />
          <p style={styles.cardText}>Front End Development</p>
        </div>
        <div style={styles.card}>
          <img src={backImg} alt="Back End Development" style={styles.cardImg} />
          <p style={styles.cardText}>Back End Development</p>
        </div>
        <div style={styles.card}>
          <img src={qaImg} alt="Quality Assurance" style={styles.cardImg} />
          <p style={styles.cardText}>Quality Assurance</p>
        </div>
        <div style={styles.card}>
          <img src={cloudImg} alt="Cloud Solutions" style={styles.cardImg} />
          <p style={styles.cardText}>Cloud Solutions</p>
        </div>
      </div>

      <ServicesDescription visible={visible} />
    </div>
  );
};

export default Programs;
