import React, { useState, useEffect } from 'react';
import thiranLogo from '../../assets/thiran.png'; // Thiran logo
import logo from '../../assets/logo.png'; // Main logo

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  const scrollToSection = (sectionId) => {
    console.log(`Scrolling to section: ${sectionId}`); // For debugging
    const offset = 100; // Adjust this value based on your layout to account for the fixed navbar
    if (sectionId === 'footer') {
      // Scroll to footer directly if "Contact Us" is clicked
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: sectionTop, behavior: 'smooth' });
        setIsOpen(false); // Close the mobile menu after clicking a link
      } else {
        console.error(`Section with id "${sectionId}" not found.`);
      }
    }
  };

  // Your existing styles
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backdropFilter: 'blur(10px)',
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  };

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const thiranLogoStyle = {
    width: isMobile ? '60px' : '80px',
    marginRight: '15px',
  };

  const logoStyle = {
    width: isMobile ? '150px' : '200px',
    height: 'auto',
  };

  const navLinksStyle = {
    display: isMobile ? 'none' : 'flex',
    listStyle: 'none',
    marginLeft: 'auto',
    gap: '20px',
    alignItems: 'center',
    marginTop: '-10px',
  };

  const mobileNavStyle = {
    position: 'fixed',
    top: '0',
    right: isOpen ? '0' : '-100%',
    height: '100vh',
    width: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'right 0.3s ease',
    zIndex: 999,
  };

  const liStyle = {
    fontSize: '18px',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    padding: '3px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    color: 'black',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const hamburgerStyle = {
    display: isMobile ? 'flex' : 'none',
    cursor: 'pointer',
    flexDirection: 'column',
    gap: '5px',
    zIndex: 1100,
  };

  const barStyle = {
    width: '25px',
    height: '3px',
    backgroundColor: 'gray',
    transition: 'all 0.3s ease',
  };

  return (
    <nav style={containerStyle}>
      <div style={logoContainerStyle}>
        <img src={thiranLogo} alt="Thiran Logo" style={thiranLogoStyle} />
        <img src={logo} alt="Main Logo" style={logoStyle} />
      </div>

      <div style={navLinksStyle}>
        <ul style={{ display: 'flex', gap: '20px', margin: '0', padding: '0', listStyle: 'none' }}>
          {['Home', 'Our Services', 'About Us', 'Our Products', 'Testimonials'].map((text, index) => (
            <li
              key={index}
              style={liStyle}
              onClick={() => scrollToSection(
                text === 'Our Services' ? 'programs' : 
                text === 'Our Products' ? 'our-products' : 
                text === 'Contact Us' ? 'footer' : 
                text.toLowerCase().replace(/\s+/g, '')
              )}
              aria-label={text}
            >
              {text}
            </li>
          ))}
          <li>
            <button style={buttonStyle} onClick={() => scrollToSection('footer')}>Contact us</button>
          </li>
        </ul>
      </div>

      <div style={mobileNavStyle}>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '30px', margin: '0', padding: '0', listStyle: 'none' }}>
          {['Home', 'Our Services', 'About Us', 'Our Products', 'Testimonials'].map((text, index) => (
            <li
              key={index}
              style={liStyle}
              onClick={() => scrollToSection(
                text === 'Our Services' ? 'programs' : 
                text === 'Our Products' ? 'our-products' : 
                text === 'Contact Us' ? 'footer' : 
                text.toLowerCase().replace(/\s+/g, '')
              )}
              aria-label={text}
            >
              {text}
            </li>
          ))}
          <li>
            <button style={buttonStyle} onClick={() => scrollToSection('footer')}>Contact us</button>
          </li>
        </ul>
      </div>

      <div style={hamburgerStyle} onClick={toggleMenu} aria-label="Toggle navigation">
        <span style={barStyle}></span>
        <span style={barStyle}></span>
        <span style={barStyle}></span>
      </div>
    </nav>
  );
};

export default Navbar;
