import React from 'react';
import Navbar from './Components/Navbar/Navbar';
import Hero from './Components/Hero/Hero';
import Programs from './Components/Programs/Programs';
import AboutUs from './Components/AboutUs/AboutUs';
import OurProducts from './Components/OurProducts/OurProducts';
import Testimonials from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer'; // Ensure this path matches your structure
import Cwithus from '../chatwithus';

const MobileView = () => {
  return (
    <div>
      <Navbar />
      <div id="home"> <Hero /> </div> {/* Add ID for smooth scrolling */}
      <div id="programs"> <Programs /> </div>
      <div id="aboutus"> <AboutUs /> </div> {/* Ensure ID matches Navbar */}
      <div id="ourProducts"> <OurProducts /> </div>
      <div id="testimonials"> <Testimonials /> </div>
      <Cwithus />
      <Footer /> {/* Include Footer component */}
    </div>
  );
};

export default MobileView;
