import React, { useState } from 'react';
import capture from '../../assets/capture.png';
import CRM from '../../assets/CRM.png';
import ecommerce from '../../assets/ecommerce.png';
import Biovision from '../../assets/Biovision.png';
import Checkin360 from '../../assets/Checkin360.png';

const OurProducts = () => {
    const [activeIndex, setActiveIndex] = useState(null); // Track which card is active

    // Function to toggle between showing image or content
    const toggleContent = (index) => {
        // Toggle the active card; hide if the same index is clicked again
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    // Array of products with corresponding content
    const products = [
       
        {
            img: CRM,
            frontText: "CRM 360",
            backText: (
                <div>
                    <strong style={{ marginBottom: '10px' }}>Better dashboard for business</strong>
                    <p style={{ margin: '10px 0' }}>Monitor your revenue with analyzed data</p>
                    <p style={{ margin: '10px 0' }}>Analyze business data effectively</p>
                    <p style={{ margin: '10px 0' }}>Monitor every activity with precision</p>
                </div>
            ),
        },
        {
            img: ecommerce,
            frontText: "Ecom 360",
            backText: (
                <div>
                    <strong style={{ marginBottom: '10px' }}>User-friendly online store</strong>
                    <p style={{ margin: '10px 0' }}>Advanced inventory management</p>
                    <p style={{ margin: '10px 0' }}>Secure payment processing</p>
                    <p style={{ margin: '10px 0' }}>Detailed sales analytics</p>
                </div>
            ),
        },
        {
            img: Biovision,
            frontText: "Bioversion 360",
            backText: (
                <div>
                    <strong style={{ marginBottom: '10px' }}>Monitor employee activity</strong>
                    <p style={{ margin: '10px 0' }}>Track employee in and out times</p>
                    <p style={{ margin: '10px 0' }}>Employee attendance and count monitoring</p>
                </div>
            ),
        },
        {
            img: Checkin360,
            frontText: "CheckIn 360",
            backText: (
                <div>
                    <strong style={{ marginBottom: '10px' }}>Dining sales monitor</strong>
                    <p style={{ margin: '10px 0' }}>50% sales increase</p>
                    <p style={{ margin: '10px 0' }}>Efficient reservation management</p>
                    <p style={{ margin: '10px 0' }}>Comprehensive inventory tracking</p>
                </div>
            ),
        },
        {
            img: capture,
            frontText: "Prograss 360",
            backText: (
                <div>
                    <div style={{ marginBottom: "20px" }}>
                        <strong>Captures 360-degree views</strong>
                    </div>
                    <p style={{ margin: '10px 0' }}>Enhanced immersive experience suitable for virtual tours and panoramic imagery</p>
                </div>
            ),
        },
    ];

    // Inline styles
    const styles = {
        container: {
            textAlign: 'left',
            overflow: 'hidden',
            padding: '20px',
        },
        heading: {
            fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        slider: {
            display: 'flex',
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            gap: '20px',
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none', // For Internet Explorer and Edge
        },
        product: {
            flex: '0 0 auto',
            width: '250px',
            height: '500px',
            position: 'relative',
            textAlign: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
        },
        productFront: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        productImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'opacity 1s ease',
        },
        productOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            transition: 'opacity 1s ease',
        },
        productText: {
            color: 'white',
            fontSize: '25px',
            fontWeight: 'bold',
        },
        productBack: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            padding: '20px',
            opacity: 0,
            transition: 'opacity 0.5s ease',
            zIndex: -1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'justify',
        },
        activeBack: {
            opacity: 1,
            zIndex: 1,
        },
        toggleButton: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            border: 'none',
            borderRadius: '5px',
            padding: '5px 10px',
            cursor: 'pointer',
        },
    };

    return (
        <div id="our-products" style={styles.container}>
            <h2 style={{fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '10px',}}>Our Products</h2>
            <div style={styles.slider}>
                {products.map((product, index) => (
                    <div
                        style={{ ...styles.product, ...(activeIndex === index ? { zIndex: 1 } : {}) }}
                        key={index}
                    >
                        <div style={styles.productFront}>
                            <img src={product.img} alt={product.frontText} style={styles.productImage} />
                            <div style={styles.productOverlay}>
                                <p style={styles.productText}>{product.frontText}</p>
                                <button style={styles.toggleButton} onClick={() => toggleContent(index)}>
                                    {activeIndex === index ? '-' : '+'}
                                </button>
                            </div>
                        </div>
                        <div
                            style={{
                                ...styles.productBack,
                                ...(activeIndex === index ? styles.activeBack : {}),
                            }}
                        >
                            {product.backText}
                            <button style={styles.toggleButton} onClick={() => toggleContent(index)}>
                                Close
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurProducts;
